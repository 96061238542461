.herosecondary {
  height: 300px;
}

.herosecondary_image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(50%) brightness(50%);
  -webkit-filter: grayscale(50%) brightness(50%);
}

.herosecondary_title_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  position: absolute;
  bottom: 0;
  color: var(--white-color);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 53%,
    rgba(0, 0, 0, 1) 100%
  );
}

.herosecondary_title {
  line-height: 1.2;
}
