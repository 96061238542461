/* Foundations */

/* Fonts */

@import url("https://fonts.googleapis.com/css?family=Cabin|Lobster&display=swap");

/*
  	Flaticon icon font: Flaticon
  	Creation date: 21/07/2019 01:09
*/

@font-face {
  font-family: "Flaticon";
  src: url("../iconfonts/Flaticon.eot");
  src: url("../iconfonts/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../iconfonts/Flaticon.woff2") format("woff2"),
    url("../iconfonts/Flaticon.woff") format("woff"),
    url("../iconfonts/Flaticon.ttf") format("truetype"),
    url("../iconfonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../iconfonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 40px;
  font-style: normal;
}

.flaticon-facebook:before {
  content: "\f100";
}
.flaticon-instagram:before {
  content: "\f101";
}

.icon-facebook:before {
  content: "\e902";
}
.icon-instagram:before {
  content: "\e901";
}

:root {
  /* Colors */

  --white-color: #ffffff;
  --black-color: #2b061e;
  --action-color: #edadc7;
  --primary-color: #89608e;
  --secondary-color: #623b5a;

  /* Font sizes*/

  --h1: 40px;
  --h2: 30px;
  --big-text: 20px;
  --normal-text: 18px;
  --small-text: 14px;
}

/* Custom classes */

h1,
h2,
.lobster {
  font-family: "Lobster", cursive !important;
}

p,
small,
strong,
h3,
.cabin {
  font-family: "Cabin", sans-serif !important;
}

h1 {
  font-size: var(--h1) !important;
}

h2 {
  font-size: var(--h2) !important;
}

.big_text {
  font-size: var(--big-text) !important;
}

h3,
p {
  font-size: var(--normal-text) !important;
}

small {
  font-size: var(--small-text) !important;
}

strong {
  font-weight: 700 !important;
}

.background_white_color {
  background: var(--white-color) !important;
}
.background_black_color {
  background: var(--black-color) !important;
}
.background_action_color {
  background: var(--action-color) !important;
}
.background_secondary_color {
  background: var(--secondary-color) !important;
}
.background_primary_color {
  background: var(--primary-color) !important;
}

.font_white_color {
  color: var(--white-color) !important;
}
.font_black_color {
  color: var(--black-color) !important;
}
.font_action_color {
  color: var(--action-color) !important;
}
.font_secondary_color {
  color: var(--secondary-color) !important;
}
.font_primary_color {
  color: var(--primary-color) !important;
}

.background_transparent {
  background: rgba(0, 0, 0, 0) !important;
}

/* Miscellaneus */

.max-height-400 {
  max-height: none;
}

.modal-content {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
}

p,
.line_height_12 {
  line-height: 1.2 !important;
}

.border_pink {
  border: 1px solid var(--action-color) !important;
}

.object_position_top {
  object-position: top !important;
}

@media screen and (min-width: 600px) {
  :root {
    --h1: 50px;
    --h2: 34px;
    --big-text: 22px;
    --normal-text: 20px;
    --small-text: 16px;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    --h1: 60px;
  }
}

@media screen and (min-width: 1280px) {
  :root {
    --h1: 70px;
  }

  .max-height-400 {
    max-height: 410px !important;
  }
}
