.eterware_container {
  width: 200px;
}

.eterware_logo {
  width: 200px;
}

.footer_image {
  height: 55px;
  width: 55px;
}

@media screen and (max-width: 767px) {
  .eterware_logo {
    padding-bottom: 20px;
  }
}
