.award {
  display: inline-block !important;
}

.card-columns {
  column-count: 1;
}

@media screen and (min-width: 768px) {
  .card-columns {
    column-count: 2;
  }
}

@media screen and (min-width: 1100px) {
  .card-columns {
    column-count: 3;
  }
}
