.Award {
  cursor: pointer;
  transition: 0.3s;
}

.Award_modal_image {
  height: 300px;
  object-fit: cover;
  object-position: top;
}

.Award:hover {
  transform: scale(1.2);
}

@media screen and (max-width: 768px) {
  .Award:hover {
    transform: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .Award {
    margin-bottom: 50px !important;
  }
}
