.header {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
}

.header_toggler {
  outline-color: var(--white-color) !important;
}

.header_background {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 70%,
    rgba(0, 0, 0, 1) 100%
  );
}
