@import url(https://fonts.googleapis.com/css?family=Cabin|Lobster&display=swap);
/* Foundations */

/* Fonts */

/*
  	Flaticon icon font: Flaticon
  	Creation date: 21/07/2019 01:09
*/

@font-face {
  font-family: "Flaticon";
  src: url(/static/media/Flaticon.a30c7158.eot);
  src: url(/static/media/Flaticon.a30c7158.eot?#iefix) format("embedded-opentype"),
    url(/static/media/Flaticon.22954684.woff2) format("woff2"),
    url(/static/media/Flaticon.6d250b3c.woff) format("woff"),
    url(/static/media/Flaticon.ea493c1a.ttf) format("truetype"),
    url(/static/media/Flaticon.d68fa9ae.svg#Flaticon) format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url(/static/media/Flaticon.d68fa9ae.svg#Flaticon) format("svg");
  }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 40px;
  font-style: normal;
}

.flaticon-facebook:before {
  content: "\f100";
}
.flaticon-instagram:before {
  content: "\f101";
}

.icon-facebook:before {
  content: "\e902";
}
.icon-instagram:before {
  content: "\e901";
}

:root {
  /* Colors */

  --white-color: #ffffff;
  --black-color: #2b061e;
  --action-color: #edadc7;
  --primary-color: #89608e;
  --secondary-color: #623b5a;

  /* Font sizes*/

  --h1: 40px;
  --h2: 30px;
  --big-text: 20px;
  --normal-text: 18px;
  --small-text: 14px;
}

/* Custom classes */

h1,
h2,
.lobster {
  font-family: "Lobster", cursive !important;
}

p,
small,
strong,
h3,
.cabin {
  font-family: "Cabin", sans-serif !important;
}

h1 {
  font-size: 40px !important;
  font-size: var(--h1) !important;
}

h2 {
  font-size: 30px !important;
  font-size: var(--h2) !important;
}

.big_text {
  font-size: 20px !important;
  font-size: var(--big-text) !important;
}

h3,
p {
  font-size: 18px !important;
  font-size: var(--normal-text) !important;
}

small {
  font-size: 14px !important;
  font-size: var(--small-text) !important;
}

strong {
  font-weight: 700 !important;
}

.background_white_color {
  background: #ffffff !important;
  background: var(--white-color) !important;
}
.background_black_color {
  background: #2b061e !important;
  background: var(--black-color) !important;
}
.background_action_color {
  background: #edadc7 !important;
  background: var(--action-color) !important;
}
.background_secondary_color {
  background: #623b5a !important;
  background: var(--secondary-color) !important;
}
.background_primary_color {
  background: #89608e !important;
  background: var(--primary-color) !important;
}

.font_white_color {
  color: #ffffff !important;
  color: var(--white-color) !important;
}
.font_black_color {
  color: #2b061e !important;
  color: var(--black-color) !important;
}
.font_action_color {
  color: #edadc7 !important;
  color: var(--action-color) !important;
}
.font_secondary_color {
  color: #623b5a !important;
  color: var(--secondary-color) !important;
}
.font_primary_color {
  color: #89608e !important;
  color: var(--primary-color) !important;
}

.background_transparent {
  background: rgba(0, 0, 0, 0) !important;
}

/* Miscellaneus */

.max-height-400 {
  max-height: none;
}

.modal-content {
  border-radius: 0px !important;
}

p,
.line_height_12 {
  line-height: 1.2 !important;
}

.border_pink {
  border: 1px solid #edadc7 !important;
  border: 1px solid var(--action-color) !important;
}

.object_position_top {
  object-position: top !important;
}

@media screen and (min-width: 600px) {
  :root {
    --h1: 50px;
    --h2: 34px;
    --big-text: 22px;
    --normal-text: 20px;
    --small-text: 16px;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    --h1: 60px;
  }
}

@media screen and (min-width: 1280px) {
  :root {
    --h1: 70px;
  }

  .max-height-400 {
    max-height: 410px !important;
  }
}

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}
.Phrase_message {
  font-family: "Cabin", sans-serif !important;
}

.hero {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.hero_image {
  object-fit: cover;
  object-position: 0 0;
  position: relative;
  max-width: 100%;
}

.hero_title_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  position: absolute;
  bottom: 0;
  color: var(--white-color);
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgba(255, 255, 255, 0)),
    color-stop(53%, rgba(0, 0, 0, 0.7)),
    to(rgba(0, 0, 0, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 53%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 53%,
    rgba(0, 0, 0, 1) 100%
  );
}

.hero_title {
  line-height: 1.2;
}

@media screen and (max-width: 410px) {
  .hero_image {
    max-width: 100%;
    object-position: 10% 0;
  }
}

@media screen and (min-width: 480px) {
  .hero_image {
    width: 100%;
  }
}

@keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 40px, 0);
            transform: translate3d(0, 40px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 40px, 0);
            transform: translate3d(0, 40px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

/* Start Bootstrap modification */

.carousel-inner,
.carousel-item {
  height: 100% !important;
}

/* End Bootstrap modification */

.SectionFluid_image {
  object-fit: cover;
  height: 100%;
}

.ButtonPink {
  min-width: 100px;
}

.ButtonPink:hover {
  background: var(--black-color) !important;
  color: var(--white-color) !important;
}

.Award {
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.Award_modal_image {
  height: 300px;
  object-fit: cover;
  object-position: top;
}

.Award:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

@media screen and (max-width: 768px) {
  .Award:hover {
    -webkit-transform: none;
            transform: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .Award {
    margin-bottom: 50px !important;
  }
}

.contact_social_network {
  color: var(--black-color) !important;
}

.contact_social_network:hover {
  color: var(--secondary-color) !important;
}

.award {
  display: inline-block !important;
}

.card-columns {
  -webkit-column-count: 1;
     -moz-column-count: 1;
          column-count: 1;
}

@media screen and (min-width: 768px) {
  .card-columns {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
}

@media screen and (min-width: 1100px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
}

.herosecondary {
  height: 300px;
}

.herosecondary_image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(50%) brightness(50%);
  -webkit-filter: grayscale(50%) brightness(50%);
}

.herosecondary_title_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  position: absolute;
  bottom: 0;
  color: var(--white-color);
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgba(255, 255, 255, 0)),
    color-stop(53%, rgba(0, 0, 0, 0.7)),
    to(rgba(0, 0, 0, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 53%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 53%,
    rgba(0, 0, 0, 1) 100%
  );
}

.herosecondary_title {
  line-height: 1.2;
}

.carrera_container {
  min-height: calc(100vh - 391px);
}

.card_header {
  cursor: pointer;
}

.header {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
}

.header_toggler {
  outline-color: var(--white-color) !important;
}

.header_background {
  background: -webkit-gradient(
    linear,
    left bottom, left top,
    from(rgba(255, 255, 255, 0)),
    color-stop(70%, rgba(0, 0, 0, 0.7)),
    to(rgba(0, 0, 0, 1))
  );
  background: -webkit-linear-gradient(
    bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 70%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 70%,
    rgba(0, 0, 0, 1) 100%
  );
}

.eterware_container {
  width: 200px;
}

.eterware_logo {
  width: 200px;
}

.footer_image {
  height: 55px;
  width: 55px;
}

@media screen and (max-width: 767px) {
  .eterware_logo {
    padding-bottom: 20px;
  }
}

